/* LandingPageView.css */

.feature {
	display: inline-flex;
	align-items: center;
	width: 3rem;
	height: 3rem;
	justify-content: center;
	font-size: 1.5rem;
}

.fw-bold {
	display: flex;
}

.h-100 {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.tiny {
	width: 70px;
}
