/* PortfolioModal.css */

.form-row {
	display: flex;
	flex-direction: column;
}

.datePicker {
	height: 37px;
	border: 1px solid #000000;
	border-radius: 5px;
	padding-left: 10px;
	padding-right: 10px;
}

.modal-body {
	display: flex;
	justify-content: flex-start;
}

.datePicker {
	height: 40px;
	width: 250px;
}

.quant {
	border: 1px solid #000000 !important;
}

.modal-subtitle {
	padding-top: 10px;
}
