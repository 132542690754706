/* CareersView.css */

body {
	margin-top: 20px;
}
.shadow-sm,
.shadow-sm--on-hover:hover {
	box-shadow: 0 0.125rem 0.25rem rgba(132, 138, 163, 0.1) !important;
}
.gradient-light--lean-left {
	background-image: -webkit-linear-gradient(110deg, #f2f3fa 0%, #fcfdff 100%);
	background-image: -ms-linear-gradient(110deg, #f2f3fa 0%, #fcfdff 100%);
}

.color--heading {
	color: #101f41 !important;
}

.color--text {
	color: #4f5464 !important;
}

.badge-circle {
	border-radius: 50%;
	height: 2.5rem;
	width: 2.5rem;
	font-size: 1rem;
	line-height: 2.5rem;
	padding: 0;
}

.text-white {
	color: #fff !important;
}

.background--white,
.bg-white {
	background-color: #fff !important;
}

.background--brand,
.background--primary,
.bg-brand,
.bg-primary {
	background-color: #4d5bed !important;
	color: #fff;
}

.background--dark,
.bg-dark {
	background: #0c2050 !important;
	color: #939eb8;
}

.background--secondary,
.bg-secondary {
	background-color: #939eb8 !important;
}

.background--tertiary,
.bg-tertiary {
	background-color: #9779e3 !important;
}

.background--danger,
.bg-danger {
	background-color: #f53d55 !important;
}

.background--success,
.bg-success {
	background-color: #66df7c !important;
}

.background--warning,
.bg-warning {
	background-color: #f6c460 !important;
}

.background--warning2,
.bg-warning2 {
	background-color: #f89c59 !important;
}

.background--info,
.bg-info {
	background-color: #52bbd3 !important;
}

.background--light,
.bg-light {
	background-color: #f6f8fb !important;
}

a {
	text-decoration: none !important;
}

.job-flex {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
}

span {
	display: flex;
	align-content: center;
	justify-content: center;
}

span .badge {
	display: flex;
	align-items: center;
}
